.App {
  text-align: center;
  background-color: #001410;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #1f1f1f;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

$vertPad: 7vh;
$sidePad: 7vw;

$numOfEls: 4;
$elMrg: 1%;

$initAT: 1s;
$initDelayStep: 0.1s;
$fullInitAT: $initAT + $initDelayStep * ($numOfEls - 1);
$moveAT: 0.6s;
$expandAT: 0.7s;
$expandDelay: 0.1s;
$bgScaleAT: 0.8s;
$fadeoutAT: $moveAT + $expandAT/2;
$indexHoverAT: 0.5s;
$closeBtnAT: 0.3s;
$closeBtnLineDelay: 0.15s;
$fullExpandAT: $moveAT + $expandDelay + $expandAT;
$contentFadeinAT: 0.5s;

@mixin elHover {
  .el:hover & {
    @content;
  }
}

@mixin elActive {
  .el.s--active & {
    @content;
  }
}

@mixin contInactive {
  .cont.s--inactive & {
    @content;
  }
}

@mixin contElActive {
  .cont.s--el-active & {
    @content;
  }
}

.cont {
  position: relative;
  overflow: hidden;
  height: 100vh;
  padding: $vertPad $sidePad;

  &__inner {
    position: relative;
    height: 100%;

    &:hover .el__bg:after {
      opacity: 1;
    }
  }
}

.el {
  $elW: (100% - $elMrg * ($numOfEls - 1)) / $numOfEls;
  $elMrgRel: percentage($elMrg / $elW);

  position: absolute;
  left: 0;
  top: 0;
  width: $elW;
  height: 100%;
  background: #252525;
  transition: transform $moveAT $expandAT, width $expandAT,
    opacity $moveAT $expandAT, z-index 0s $moveAT + $expandAT;
  will-change: transform, width, opacity;

  &:not(.s--active) {
    cursor: pointer;
  }

  &__overflow {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  &__inner {
    overflow: hidden;
    position: relative;
    height: 100%;
    transition: transform $initAT;

    @include contInactive {
      transform: translate3d(0, 100%, 0);
    }
  }

  &__bg {
    position: relative;
    width: calc(100vw - #{$sidePad * 2});
    height: 100%;
    transition: transform $moveAT $expandAT;
    will-change: transform;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -5%;
      width: 100%;
      height: 110%;
      background-size: cover;
      background-position: center center;
      transition: transform $initAT;
      transform: translate3d(0, 0, 0) scale(1);

      @include contInactive {
        transform: translate3d(0, -100%, 0) scale(1.2);
      }

      @include elActive {
        transition: transform $bgScaleAT;
      }
    }

    &:after {
      $opacityAT: 0.5s;

      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity $opacityAT;

      @include contElActive {
        transition: opacity $opacityAT $fullExpandAT;
        opacity: 1 !important;
      }
    }
  }

  &__preview-cont {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s $fullInitAT - 0.2s;

    @include contInactive {
      opacity: 0;
      transform: translateY(10px);
    }

    @include contElActive {
      opacity: 0;
      transform: translateY(30px);
      transition: all 0.5s;
    }
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    text-shadow: 0 0 10px #000000, 0 0 5px #000000, 0 0 5px #000000;
  }

  &__content {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // padding: 30px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.1s;

    @include elActive {
      z-index: 2;
      opacity: 1;
      pointer-events: auto;
      transition: all $contentFadeinAT $fullExpandAT;
      overflow: auto;
    }
  }

  &__close-btn {
    z-index: -1;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    opacity: 0;
    pointer-events: none;
    transition: all 0s $closeBtnAT + $closeBtnLineDelay;
    cursor: pointer;

    @include elActive {
      z-index: 5;
      opacity: 1;
      pointer-events: auto;
      transition: all 0s $fullExpandAT;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 8px;
      margin-top: -4px;
      background: #fff;
      opacity: 0;
      transition: opacity 0s;

      @include elActive {
        opacity: 1;
      }
    }

    &:before {
      transform: rotate(45deg) translateX(100%);

      @include elActive {
        transition: all $closeBtnAT $fullExpandAT
          cubic-bezier(0.72, 0.09, 0.32, 1.57);
        transform: rotate(45deg) translateX(0);
      }
    }

    &:after {
      transform: rotate(-45deg) translateX(100%);

      @include elActive {
        transition: all $closeBtnAT $fullExpandAT + $closeBtnLineDelay
          cubic-bezier(0.72, 0.09, 0.32, 1.57);
        transform: rotate(-45deg) translateX(0);
      }
    }
  }

  &__index {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: $vertPad * -1;
    width: 100%;
    height: 100%;
    min-height: 250px;
    text-align: center;
    font-size: 100vw / $numOfEls;
    line-height: 0.85;
    font-weight: bold;
    transition: transform $indexHoverAT,
      opacity $moveAT/2 $expandAT + $expandDelay + $moveAT;
    transform: translate3d(0, 1vw, 0);

    @include elHover {
      transform: translate3d(0, 0, 0);
    }

    @include contElActive {
      transition: transform $indexHoverAT, opacity $moveAT/2;
      opacity: 0;
    }

    &-back,
    &-front {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    &-back {
      color: #2f3840;
      opacity: 0;
      transition: opacity $indexHoverAT/2 $indexHoverAT/2;

      @include elHover {
        transition: opacity $indexHoverAT/2;
        opacity: 1;
      }
    }

    &-overlay {
      overflow: hidden;
      position: relative;
      transform: translate3d(0, 100%, 0);
      transition: transform $indexHoverAT 0.1s;
      color: transparent;

      &:before {
        content: attr(data-index);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        transform: translate3d(0, -100%, 0);
        transition: transform $indexHoverAT 0.1s;
      }

      @include elHover {
        transform: translate3d(0, 0, 0);

        &:before {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  @for $i from 0 to $numOfEls {
    &:nth-child(#{$i + 1}) {
      $x: (100% + $elMrgRel) * $i;
      transform: translate3d($x, 0, 0);
      transform-origin: $x + 50% 50%;

      @include contElActive {
        &:not(.s--active) {
          transform: scale(0.5) translate3d($x, 0, 0);
          opacity: 0;
          transition: transform $fadeoutAT, opacity $fadeoutAT;
        }
      }

      .el__inner {
        transition-delay: $initDelayStep * $i;
      }

      .el__bg {
        transform: translate3d($elW * $i * -1, 0, 0);

        &:before {
          transition-delay: 0.1s * $i;
        }
      }
    }
  }

  &:nth-child(#{1}) {
    .el__bg {
      &:before {
        background-image: url("images/new_background.jpg");
      }
    }
  }

  &:nth-child(#{2}) {
    .el__bg {
      &:before {
        background-image: url("images/new_background.jpg");
      }
    }
  }

  &:nth-child(#{3}) {
    .el__bg {
      &:before {
        background-image: url("images/new_background.jpg");
      }
    }
  }

  &:nth-child(#{4}) {
    .el__bg {
      &:before {
        background-image: url("images/new_background.jpg");
      }
    }
  }

  &:nth-child(#{5}) {
    .el__bg {
      &:before {
        background-image: url("images/new_background.jpg");
      }
    }
  }

  &:hover {
    .el__bg:after {
      opacity: 0;
    }
  }

  &.s--active {
    z-index: 1;
    width: 100%;
    transform: translate3d(0, 0, 0);
    transition: transform $moveAT, width $expandAT $moveAT + $expandDelay,
      z-index 0s;

    .el__bg {
      transform: translate3d(0, 0, 0);
      transition: transform $moveAT;

      &:before {
        transition-delay: $moveAT;
        transform: scale(1.1);
      }
    }
  }
}

.icon-link {
  position: absolute;
  left: 5px;
  top: 30px;
  text-decoration: none;
  background-color: white;
  border: solid 2px #7C9A3F;
  border-radius: 15px;
  width: 12vw;

  img {
    width: 100%;
    vertical-align: top;
  }

  &--right {
    left: auto;
    right: 30px;
  }
}

.panel-content {
  height: 60vh;
}

.rotated {
  width: 60vh;
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 1.5rem;
}


.background {
  background: rgba(0, 0, 0, 0.5);
}

.attached-image
{
  height: 25vh;
}
figure{
  overflow: hidden;
}
figcaption{
    font-style: italic;
}
.map {
  width: 100%;
  height: 40vh;
}

p {
  font-size: 1.5rem;
}

.download-image{
    height: 8vh;
}